import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import ModularBlocks from 'components/ModularBlocks';
import pageShape from 'shapes/pageShape';

const BodyContainer = styled.div`
  overflow: hidden;
`;

const TitleContainer = styled.div`
  margin-bottom: 2.75rem;
  width: 88%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1440px) {
    max-width: none;
  }
`;

const PageTitle = styled(Typography)`
  line-height: 1.2;
  margin-bottom: 1rem;
`;

const Subtitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-weight: 600;
  text-transform: uppercase;
`;

DefaultBody.propTypes = {
  page: pageShape,
  pageName: PropTypes.string,
};

function DefaultBody({ page, pageName, ...props }) {
  const { pageBody, title, subtitle, displayPageTitle = true } = page;

  return (
    <BodyContainer>
      {displayPageTitle && (
        <TitleContainer>
          {title && <PageTitle variant='h1'>{title}</PageTitle>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
      )}
      <ModularBlocks modularBlocks={pageBody} page={pageName} {...props} />
    </BodyContainer>
  );
}

export default DefaultBody;
