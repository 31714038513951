import React from 'react';
import styled, { css } from 'styled-components';
import { switchProp, theme } from 'styled-tools';
import Accordion from 'components/Accordion';

const ConceptAccordion = styled(Accordion)`
  .MuiAccordionSummary-root {
    border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
    min-height: auto;
    padding: 0 1.4rem 0 0.75rem;

    &.Mui-expanded {
      min-height: auto;
      border-bottom: 0;
    }
  }

  .MuiAccordionSummary-content {
    margin: 0.5rem 0;

    &.Mui-expanded {
      margin: 0.5rem 0;
    }

    .MuiTypography-root {
      font-family: ${theme('typography.fontFamilySecondary')};
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .MuiAccordionSummary-expandIcon {
    font-size: 0.625rem;
    padding: 0.625rem;

    &:hover,
    &:focus,
    &:active {
      color: ${theme('colors.ttRed')};
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }

  .MuiFormGroup-root {
    padding-left: 1rem;

    > div {
      padding-bottom: 0.3125rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  label {
    margin-left: 0;
    margin-right: 0;
    text-transform: uppercase;

    .MuiTypography-root {
      font-family: ${theme('typography.fontFamilySecondary')};
      font-size: 0.6875rem;
    }
  }

  ${switchProp('$menuType', {
    filter: css`
      MuiCollapse-container {
        background: ${theme('colors.backgroundGray')};
      }

      .MuiAccordionDetails-root {
        border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
        padding: 0.5rem 0;
      }

      .MuiAccordionSummary-expandIcon {
        font-size: 0.875rem;
        padding: 0.5rem;
        transform: none;

        &.Mui-expanded {
          transform: none;
        }
      }
    `,
    category: css`
      .MuiAccordionDetails-root {
        padding: 0;
      }
    `,
  })}
`;

export default ConceptAccordion;
