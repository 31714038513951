import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from './SearchBar';

Search.propTypes = {
  checkForSearchTerm: PropTypes.func.isRequired,
  isMobileSearch: PropTypes.bool,
};

function Search({ checkForSearchTerm, isMobileSearch = false }) {
  return (
    <SearchBar
      checkForSearchTerm={checkForSearchTerm}
      isMobileSearch={isMobileSearch}
    />
  );
}

export default React.memo(Search);
