import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import MuiSearchBar from 'material-ui-search-bar';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';

const SidebarContainer = styled.div`
  background-color: ${theme('colors.white')};
  border-right: 0.0625rem solid ${theme('colors.borderGray')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SearchContainer = styled.div`
  padding: 0.75rem;
  width: 100%;

  ${props => props.theme.breakpoints.up('sm')} {
    border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  }
`;

const StyledSearchBar = styled(MuiSearchBar)`
  background-color: ${theme('colors.iconHoverBackgroundGray')};
  border-style: none;
  box-shadow: none;
  border-radius: 50px;
  -webkit-appearance: none;
  height: 2.5rem;

  > div {
    width: calc(100% - 5rem);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .MuiInputBase-input {
    color: #666666;
    font-family: ${theme('typography.fontFamilySecondary')};
    font-size: 0.8125rem;
    font-weight: 400;
    text-transform: uppercase;

    &::placeholder {
      color: #333333;
      opacity: 1;
    }
  }

  .MuiIconButton-root {
    color: ${theme('colors.black')};
    border-radius: 0;
    
    &:hover {
      color: ${theme('colors.ttRed')};
      background-color: transparent;
    }

    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
  }
`;


SearchBar.propTypes = {
  checkForSearchTerm: PropTypes.func.isRequired,
  isMobileSearch: PropTypes.bool,
};

function SearchBar({ checkForSearchTerm, isMobileSearch = false }) {
  const { activeTerm, searchTerm, setSearchTerm } = useKeyConceptsContext();

  useEffect(() => {
    // The search and clear buttons are built into the material ui search component
    // and cannot be accessed in the component jsx. Setting up logic here to allow
    // for searching and clearing out of the search based on event listeners for the
    // applicable clicks
    const allButtons = document.getElementById('key-concepts-search')?.querySelectorAll('button');

    const handleSearch = (event) => {
      if (event) {
        setSearchTerm(event);
      }
    }

    const handleClearSearch = (event) => {
      if (event) {
        setSearchTerm('');
      }
    }

    allButtons[0].addEventListener('click', handleSearch);
    allButtons[1].addEventListener('click', handleClearSearch);

    return () => {
      allButtons[0].addEventListener('click', handleSearch);
      allButtons[1].addEventListener('click', handleClearSearch);
    }
  }, [searchTerm]);

  return (
    <SearchContainer id='key-concepts-search'>
      <StyledSearchBar
        onChange={checkForSearchTerm}
        placeholder='Search Key Concepts...'
        value={searchTerm}
        disableRipple
        disableTouchRipple
      />
    </SearchContainer>
  );
}

export default SearchBar;
