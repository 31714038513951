import React from 'react';
import ErrorPage from './index';
import { Route } from 'react-router-dom';

function ServerErrorPage() {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.statusCode = 500;
        }

        return (
          <ErrorPage
            title='500 Server Error'
            heroHeader='500 Server Error'
            pageHeader='Something went wrong'
            bodyText='There was a problem while trying to load the page.'
          />
        );
      }}
    />
  );
}

export default ServerErrorPage;
