import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import definitionCategoryShape from 'shapes/definitionCategoryShape';
import definitionShape from 'shapes/definitionShape';
import Menus from './Menus';

const Wrapper = styled.div`
  background: ${theme('palette.common.white')};
  position: fixed;
  top: 129px;
  left: 0;
  right: 0;
  height: calc(100vh - 129px);
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  transform: translateY(-100vh) translateY(129px);
  z-index: 1104;

  ${ifProp('$open', css`
    transform: translateY(0px);
    visibility: visible;
  `)};
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

MobileMenusWrapper.propTypes = {
  categoryFilters: PropTypes.shape({
    categoryUids: PropTypes.array
  }),
  categories:  PropTypes.arrayOf(definitionCategoryShape),
  handleChange: PropTypes.func.isRequired,
  termsByCategory: PropTypes.arrayOf(definitionShape),
};

function MobileMenusWrapper({
  categoryFilters,
  categories,
  handleChange,
  termsByCategory
}) {
  const { mobileMenuOpen, toggleMobileMenu } = useKeyConceptsContext();

  useEffect(() => {
    const stickyFooter = document.getElementById('sticky-ad-container');

    if (mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
      stickyFooter?.classList.add('mobile-concept-menu');
    }

    return () => {
      document.body.style.overflow = '';
      stickyFooter?.classList.remove('mobile-concept-menu');
    }
  }, [mobileMenuOpen]);

  return (
    <Wrapper className='key-concepts-mobile-nav' $open={mobileMenuOpen}>
      <ContentWrap>
        <Menus
          categoryFilters={categoryFilters}
          categories={categories}
          handleChange={handleChange}
          isMobileMenu={true}
          termsByCategory={termsByCategory}
        />
      </ContentWrap>
    </Wrapper>
  );
}

export default React.memo(MobileMenusWrapper);
