export default function mapRoute(route) {
  const ABOUT_US = 'About Us';
  const HOMEPAGE = 'Homepage';
  const NEWS_AND_INSIGHTS = 'News & Insights';
  const DEFINITIONS = 'Definitions';
  const EVENTS = 'Events';
  const KEY_CONCEPTS = 'Key Concepts';
  const RESOURCES = 'Resources';
  const SEARCH = 'Search';
  const SHOW = 'Show';
  const TALENT_PROFILE = 'Talent Profile';
  const LIVE_STREAM = 'Live Stream';

  const routeMap = {
    '/': HOMEPAGE,
    '/about-us': ABOUT_US,
    '/contact-us': ABOUT_US,
    '/faq': ABOUT_US,
    '/news-pr-awards': ABOUT_US,
    '/our-platforms': ABOUT_US,
    '/tastytrade': ABOUT_US,
    '/legal-stuff': ABOUT_US,
    '/authors': NEWS_AND_INSIGHTS,
    '/blog': NEWS_AND_INSIGHTS,
    '/news-insights': NEWS_AND_INSIGHTS,
    '/glossary': DEFINITIONS,
    '/definitions': DEFINITIONS,
    '/events': EVENTS,
    '/concepts-strategies': KEY_CONCEPTS,
    '/learn-course': RESOURCES,
    '/luckbox': RESOURCES,
    '/trader-resources': RESOURCES,
    '/backtest': RESOURCES,
    '/search': SEARCH,
    '/episodes': SHOW,
    '/on-demand': SHOW,
    '/shows': SHOW,
    '/talent': TALENT_PROFILE,
    '/live-stream': LIVE_STREAM,
  }

  return routeMap[route] || 'Uncategorized Page Type';
}