import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import ActiveBody from './components/ActiveBody';
import DefaultBody from './components/DefaultBody';
import DesktopPageMenu from './components/DesktopPageMenu';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import definitionShape from 'shapes/definitionShape';
import { createHeaderId } from 'utils/functions';
import pageShape from 'shapes/pageShape';
import { dataLayerPush } from 'utils/dataLayer';

const KeyConceptsContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 92vh;

  .modular-block-episodeFeed {
    max-width: none;
  }

  .modular-block-basicTextBlock {
    padding: 0;
  }
`;

const ConceptContainer = styled.div`
  margin-left: auto;
  padding-top: 3.625rem;
  width: 100%;

  ${ifProp('$activeTerm', css`
    ${props => props.theme.breakpoints.up('sm')} {
      width: calc(100% - 49px);
    }

    ${ifProp('$menuOpen', css`
      ${props => props.theme.breakpoints.up('sm')} {
        width: calc(100% - 278px);
      }
    `)}
  `)}
`;

Main.propTypes = {
  offsetTop: PropTypes.number,
  page: pageShape,
};

function Main({ page, offsetTop, ...props }) {
  const [open, setOpen] = useState(true);
  const categoryTitle = page?.category?.title;
  const pageName = get(page, 'url').replace('/', '');
  const { activeTerm } = useKeyConceptsContext();

  const activeTermData = page;
  const toggleDrawer = () => {
    setOpen(!open);
  };

  let menuItems = [];
  activeTermData?.pageBody?.forEach((item) => {
    const callout = item?.calloutBlock;

    if (callout) {
      const { contentHeader, displayOnToc, tocHeader } = callout?.blockHeader;
      const callouts = callout?.callouts;

      // If there are any subheaders create an array and push them with the menuItem entry
      let subheaders = [];
      if (callouts && callouts.length > 0) {
        subheaders = callouts?.reduce((items, item) => {
          const toAdd = item?.calloutColumns.reduce((items, item) => {
            const { displayOnToc, header, tocHeader } = item?.callout;
            const subheader = tocHeader ? tocHeader : header;

            if (displayOnToc) {
              items.push({
                hash: '#' + createHeaderId(subheader),
                header: subheader
              });
            }
            return items;
          }, []);

          if (toAdd.length > 0) {
            items = items.concat(toAdd);
          }

          return items;
        }, []);
      }

      if (displayOnToc && !tocHeader) {
        menuItems.push({
          hash: '#' + createHeaderId(contentHeader),
          header: contentHeader,
          subheaders: subheaders
        });
      } else if (displayOnToc && tocHeader) {
        menuItems.push({
          hash: '#' + createHeaderId(tocHeader),
          header: tocHeader,
          subheaders: subheaders
        });
      }
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined' && !!categoryTitle && window?.dataLayer?.length > 0) {
      dataLayerPush(categoryTitle, 'key_concept_category');
    }
  }, [categoryTitle]);

  return (
    <KeyConceptsContainer>
      <Hidden xsDown implementation='css'>
        {activeTerm && (
          <DesktopPageMenu
            menuItems={menuItems}
            offsetTop={offsetTop}
            open={open}
            toggleDrawer={toggleDrawer}
          />
        )}
      </Hidden>
      <ConceptContainer
        $activeTerm={activeTerm}
        $menuOpen={open}
        id={'key-concept-container'}
      >
        {!activeTerm && (
          <DefaultBody page={page} pageName={pageName} />
        )}
        {activeTerm && (
          <ActiveBody
            activeTermData={activeTermData}
            menuItems={menuItems}
            pageName={pageName}
          />
        )}
      </ConceptContainer>
    </KeyConceptsContainer>
  );
}

export default Main;
