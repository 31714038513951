import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, theme } from 'styled-tools';
import MuiIconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import useElementSize from 'hooks/useElementSize';
import ContentsMenu from './ContentsMenu';
import Icon from 'components/Icon';
import { useSiteSettings } from 'context/SiteSettingsContext';

const TITLE_OFFSET = 56;

const CustomDrawer = styled.div`
  border-right: 0.0625rem solid ${theme('colors.borderGray')};
  display: flex;
  flex-direction: column;
  width: 4rem;
  height: calc(100vh - 6.25rem);
  overflow-y: scroll;
  position: sticky;
  top: 6.25rem;
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  overscroll-behavior: contain;
  
  ${ifProp(
    '$displayAlert',
    css`
      height: calc(100vh - 9.75rem);
      top: 9.75rem;
    `
  )}
  
  ${props => props.theme.breakpoints.down('md')} {
    top: 3.75rem;
    height: calc(100vh - 3.75rem);

    ${ifProp(
      '$displayAlert',
      css`
        height: calc(100vh - 7.25rem);
        top: 7.25rem;
      `
    )}
  }

  ${ifProp(
    '$expanded',
    css`
      max-width: 278px;
      width: 100%;
    `
  )}
`;

const ToggleContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0.75rem 0.5rem;
`;

const MenuHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MenuTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
`;

const IconButton = styled(MuiIconButton)`
  color: ${theme('colors.black')};

  &:hover {
    svg {
      color: ${theme('colors.ttRed')};
    }
  }

  &.Mui-disabled {
    color: ${theme('colors.black')};
  }

  svg {
    font-size: 1rem;
  }
`;

const ContentsMenuContainer = styled.div`
  height: 100%;

  ${ifProp(
    '$menuHeight',
    css`
    height ${prop('$menuHeight')}px;
  `
  )}
`;

DesktopPageMenu.propTypes = {
  menuItems: PropTypes.array,
  offsetTop: PropTypes.number,
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

function DesktopPageMenu({ menuItems, offsetTop, open, toggleDrawer }) {
  const drawerRef = useRef(null);
  const { height } = useElementSize(drawerRef);
  const menuHeight = height - offsetTop - TITLE_OFFSET;
  const { displayAlert } = useSiteSettings();

  return (
    <CustomDrawer $displayAlert={displayAlert} $expanded={open} ref={drawerRef}>
      <ToggleContainer>
        <IconButton onClick={toggleDrawer} disabled={open}>
          <FormatListBulletedIcon />
        </IconButton>
        {open && (
          <MenuHeader>
            <MenuTitle>Contents</MenuTitle>
            <IconButton onClick={toggleDrawer}>
              <Icon className='collapse-icon' icon='chevronLeft' size={0.8} />
            </IconButton>
          </MenuHeader>
        )}
      </ToggleContainer>
      <ContentsMenuContainer $menuHeight={menuHeight}>
        {open && <ContentsMenu menuItems={menuItems} />}
      </ContentsMenuContainer>
    </CustomDrawer>
  );
}

export default DesktopPageMenu;
