import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ModularBlocks from 'components/ModularBlocks';
import MobilePageMenu from './MobilePageMenu';
import { makeBlogContributorUrl } from 'utils/linkUtils';

const TermContainer = styled.div`
  padding: 0 1rem;
  width: 88%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1440px) {
    max-width: 1024px;
  }

  .modular-block-episodeFeed,
  .modular-block-blogPostFeed {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }

  img {
    max-width: 100%;
  }
`;

const PageTitle = styled(Typography)`
  line-height: 1.2;
  margin-bottom: 1rem;
  font-size: 2rem;
`;

const Metadata = styled(Typography)`
  font-weight: 300;
  margin-bottom: 1rem;

  a {
    color: ${theme('colors.black')};
  }
`;

ActiveBody.propTypes = {
  activeTermData: PropTypes.object,
  menuItems: PropTypes.array,
  pageName: PropTypes.string,
};

function ActiveBody({ activeTermData, menuItems, pageName }) {
  const byline = activeTermData?.byline;
  const reviewer = activeTermData?.reviewerByline;
  const updated = activeTermData?.updated;
  return (
    <>
      <TermContainer>
        <PageTitle variant='h1'>{activeTermData?.pageTitle}</PageTitle>
        <Metadata>
          {byline && (
            <span>
              By: <Link to={makeBlogContributorUrl(byline)}>{byline.title}</Link>.
            </span>
          )}
          {reviewer && (
            <span>
              &nbsp;Reviewed By: <Link to={makeBlogContributorUrl(reviewer)}>{reviewer.title}</Link>.
            </span>
          )}
          {updated && (
            <span>
              <br/>
              Updated {dayjs(updated).format('MMM DD, YYYY')}
            </span>
          )}
        </Metadata>
      </TermContainer>
      <TermContainer>
        <Hidden smUp implementation='css'>
          <MobilePageMenu
            menuItems={menuItems}
          />
        </Hidden>
        <ModularBlocks
          modularBlocks={activeTermData?.pageBody}
          page={pageName}
        />
      </TermContainer>
    </>
  );
}

export default React.memo(ActiveBody);
