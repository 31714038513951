import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, theme } from 'styled-tools';
import { Scrollchor, easeOutQuad } from 'react-scrollchor';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';

const OrderedList = styled.ol`
  counter-reset: item;
  list-style-type: none;
  padding: 0 1rem;
`;

const MenuItem = styled.li`
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 0.875rem;
  position: relative;

  &:before {
    content: counter(item);
    counter-increment: item;
    font-weight: 400;
    left: 0;
    padding-right: 0.625rem;
    position: absolute;
  }

  a {
    color: ${theme('colors.black')};
    text-decoration: none;

    &.active,
    &:hover {
      color: ${theme('colors.ttRed')};
    }
  }

  ol {
    flex: 0 1 100%;
  }
`;

const ScrollContainer = styled.div`
  a {
    color: ${theme('colors.black')};
    display: block;
    padding-left: 1.0625rem;
    text-decoration: none;

    &.active,
    &:hover {
      color: ${theme('colors.ttRed')};
    }
  }

  ${ifProp('$largeList', css`
    a {
      padding-left: 1.5rem;
    }
  `)}
`;

const SubMenuItem = styled(MenuItem)`
  margin-top: 0.875rem;
  margin-bottom: 0;

  :before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }

  a {
    display: block;
    padding-left: 1.75rem;
  }

  ${ifProp('$largeList', css`
    a {
      padding-left: 2.1875rem;
    }
  `)}
`;

ContentsMenu.propTypes = {
  menuItems: PropTypes.array,
};

function ContentsMenu({ menuItems }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { activeContentItem, setActiveContentItem } = useKeyConceptsContext();

  return (
    <OrderedList>
      {menuItems.map((item, index) => (
        <MenuItem key={index}>
          <ScrollContainer
            onClick={() => setActiveContentItem(item.hash)}
            $largeList={index >= 9}
          >
            <Scrollchor
              animate={{
                duration: 400,
                easing: easeOutQuad,
                offset: isMobile ? -192 : -160,
              }}
              className={item.hash === activeContentItem ? 'active' : ''}
              to={item.hash}
            >
              {item.header}
            </Scrollchor>
          </ScrollContainer>
          {item.subheaders.length > 0 && (
            <OrderedList>
              {item.subheaders.map((subItem, subIndex) => (
                <SubMenuItem
                  onClick={() => setActiveContentItem(subItem.hash)}
                  $largeList={subIndex >= 9}
                  key={subIndex}
                >
                  <Scrollchor
                    animate={{
                      duration: 400,
                      easing: easeOutQuad,
                      offset: isMobile ? -192 : -160,
                    }}
                    className={subItem.hash === activeContentItem ? 'active' : ''}
                    to={subItem.hash}
                  >
                    {subItem.header}
                  </Scrollchor>
                </SubMenuItem>
              ))}
            </OrderedList>
          )}
        </MenuItem>
      ))}
    </OrderedList>
  );
}

export default ContentsMenu;
