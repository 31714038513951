import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from 'styled-tools';

const StyledContainer = styled(Container)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`;

const PaddedTypography = styled(Typography)`
  padding-top: 2rem;
  padding-bottom: 3rem;
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  flex-shrink: 1;
  align-items: center;
  margin-right: 1rem;
  text-decoration: none;
  font-size: 1.2rem;
  color: ${theme('colors.black')};
  
  > svg {
    margin-left: 0.5rem;
  }
`;

function ErrorPage({
  title,
  heroHeader,
  pageHeader,
  bodyText,
  homeButton = false,
  onDemandButton = false,
}) {
  return (
    <StyledContainer>
      <Helmet>{title}</Helmet>
      {heroHeader && (<Typography variant='h2'>{heroHeader}</Typography>)}
      <Typography variant='h2'>{pageHeader}</Typography>
      <PaddedTypography variant='body1'>{bodyText}</PaddedTypography>
      {homeButton && (
        <StyledLink to={'/'}>
          Go Home <Icon icon={'arrowRight'} />
        </StyledLink>
      )}
      {onDemandButton && (
        <StyledLink to={'/on-demand'}>
          Check out our shows <Icon icon={'arrowRight'} />
        </StyledLink>
      )}
    </StyledContainer>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroHeader: PropTypes.string,
  pageHeader: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  homeButton: PropTypes.bool,
  onDemandButton: PropTypes.bool,
};

export default React.memo(ErrorPage);
