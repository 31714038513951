import React from 'react';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ButtonPrimary from 'components/ButtonPrimary';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';

const NoResultsContainer = styled.div`
  padding: 1rem;

  button {
    font-size: 0.8125rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    width: 100%;
  }
`;

const NoResultsTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

function NoResults() {
  const history = useHistory();
  const { searchTerm } = useKeyConceptsContext();

  return (
    <NoResultsContainer>
      <NoResultsTitle>No Results for "{searchTerm}"</NoResultsTitle>
      <ButtonPrimary onClick={() => { history.push(`search?q=${searchTerm}`) }}>
        Search All of Tasty<em>live</em>
      </ButtonPrimary>
    </NoResultsContainer>
  );
}

export default React.memo(NoResults);
