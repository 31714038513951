import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import getDefinitionQuery from '../../graphql/queries/getDefinition.graphql';
import ServerErrorPage from 'routes/ErrorPages/ServerErrorPage';
import NotFoundPage from 'routes/ErrorPages/NotFoundPage';
import LoadingPage from 'components/LoadingPage';
import { KeyConceptsProvider } from 'context/Page/KeyConcepts';
import KeyConceptsPage from 'routes/Page/KeyConceptsPage';
import useServerCacheHeaders from 'hooks/useServerCacheHeaders';
import httpCacheConfig, { addCacheTags } from 'config/httpCache';
import { dataLayerPush } from 'utils/dataLayer';
import mapRoute from 'utils/linkMapper';

KeyConceptsDefinitionPage.propTypes = {
  match: PropTypes.object,
};

function KeyConceptsDefinitionPage({ match }) {
  const definitionUrl = match.url;
  const location = useLocation();

  const { loading, error, data } = useQuery(getDefinitionQuery, {
    variables: { url: definitionUrl },
  });

  useServerCacheHeaders(
    addCacheTags(httpCacheConfig.keyConceptsDefinitionPage, [
      data?.definition?.uid,
      'header',
      'footer'
    ]),
    { skip: !data?.definition }
  );

  useEffect(() => {
    // Ensure window is there (will return undefined during initial render
    // with SSR); also checking for the dataLayer length will ensure there
    // is not a double push of page type on initial load / render
    if (typeof window !== 'undefined' && window?.dataLayer?.length > 0) {
      dataLayerPush(mapRoute('/concepts-strategies'), 'page_type');
    }
  }, [])

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ServerErrorPage />;
  }

  if (!data.definition) {
    return <NotFoundPage />;
  }

  const defData = { ...data.definition, url: location.pathname };

  return (
    <KeyConceptsProvider activeTerm={defData} activeContentItem={location.hash}>
      <KeyConceptsPage page={defData} />
    </KeyConceptsProvider>
  );
}

export default KeyConceptsDefinitionPage;
