import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import useElementSize from 'hooks/useElementSize';
import definitionCategoryShape from 'shapes/definitionCategoryShape';
import definitionShape from 'shapes/definitionShape';
import Search from './components/Search';
import Menus from './components/Menus';
import { useSiteSettings } from 'context/SiteSettingsContext';

const SEARCH_OFFSET = 69;

const SidebarContainer = styled.div`
  background-color: ${theme('colors.white')};
  border-right: 0.0625rem solid ${theme('colors.borderGray')};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6.25rem);
  overflow-y: scroll;
  width: inherit;
  position: sticky;
  top: 6.25rem;
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  overscroll-behavior: contain;
  
  ${ifProp(
    '$displayAlert',
    css`
      height: calc(100vh - 9.75rem);
      top: 9.75rem;
    `
  )}
  
  ${props => props.theme.breakpoints.down('md')} {
    top: 3.75rem;
    height: calc(100vh - 3.75rem);

    ${ifProp(
      '$displayAlert',
      css`
        height: calc(100vh - 7.25rem);
        top: 7.25rem;
      `
    )}
  }

  ${ifProp(
    '$width',
    css`
      width: ${prop('$width')}px;

      ${(props) => props.theme.breakpoints.up('lg')} {
        width: 16.25rem;
      }
    `
  )}
`;

const MenuContainer = styled.div`
  min-height: 50vh;
  height: 100%;

  ${ifProp(
    '$menuHeight',
    css`
    height ${prop('$menuHeight')}px;
  `
  )}
`;

Sidebar.propTypes = {
  categoryFilters: PropTypes.shape({
    categoryUids: PropTypes.array,
  }),
  checkForSearchTerm: PropTypes.func.isRequired,
  categories: definitionCategoryShape,
  handleChange: PropTypes.func.isRequired,
  offsetTop: PropTypes.number,
  termsByCategory: PropTypes.arrayOf(definitionShape),
  width: PropTypes.number,
};

function Sidebar({
  categoryFilters,
  checkForSearchTerm,
  categories,
  handleChange,
  offsetTop,
  termsByCategory,
  width,
}) {
  const { displayAlert } = useSiteSettings();

  const menuRef = useRef(null);
  const { height } = useElementSize(menuRef);
  const menuHeight = height - offsetTop - SEARCH_OFFSET;

  return (
    <SidebarContainer $displayAlert={displayAlert} $width={width} ref={menuRef}>
      <Search checkForSearchTerm={checkForSearchTerm} />
      <MenuContainer $menuHeight={menuHeight}>
        <Menus
          categoryFilters={categoryFilters}
          categories={categories}
          handleChange={handleChange}
          termsByCategory={termsByCategory}
        />
      </MenuContainer>
    </SidebarContainer>
  );
}

export default Sidebar;
