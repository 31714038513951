import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'styled-tools';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ContentsMenu from './ContentsMenu';

const MenuContainer = styled.div`
  background: ${theme('colors.white')};
  padding: 1rem 0;
`;

const MenuHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  width: 100%;

  svg {
    font-size: 1rem;
    margin-right: 0.625rem;
  }
`;

const MenuTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
`;

MobilePageMenu.propTypes = {
  menuItems: PropTypes.array,
};

function MobilePageMenu({ menuItems }) {
  return (
    <MenuContainer>
      <MenuHeader>
        <FormatListBulletedIcon />
        <MenuTitle>Contents</MenuTitle>
      </MenuHeader>
      <ContentsMenu menuItems={menuItems} />
    </MenuContainer>
  );
}

export default MobilePageMenu;
