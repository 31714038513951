import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import CheckboxGroup from 'components/CheckboxGroup';
import definitionCategoryShape from 'shapes/definitionCategoryShape';
import ConceptAccordion from './ConceptAccordion';

FilterMenu.propTypes = {
  categoryFilters: PropTypes.shape({
    categoryUids: PropTypes.array,
  }),
  categoryItems: PropTypes.arrayOf(definitionCategoryShape),
  handleChange: PropTypes.func.isRequired,
};

function FilterMenu({ categoryFilters, categoryItems, handleChange }) {
  const categories = categoryItems.map(category => ({
    name: category.uid,
    label: category.title,
    checked: get(categoryFilters, 'categoryUids', []).includes(category.uid),
  }));

  return (
    <ConceptAccordion $menuType='filter' icon='filterSlider' title='Filter'>
      <CheckboxGroup
        onChange={(categoryUids) => handleChange({ categoryUids })}
        values={categories}
      />
    </ConceptAccordion>
  );
}

export default FilterMenu;
